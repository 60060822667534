import { useAuth0 } from '@auth0/auth0-react';
import { useSearchParams } from 'react-router-dom';

import { lazyImport } from '@/utils/lazyImport';

const { ProtectedRoutes } = lazyImport(() => import('./ProtectedRoutes'), 'ProtectedRoutes');
const { PublicRoutes } = lazyImport(() => import('./PublicRoutes'), 'PublicRoutes');

export const AppRoutes: React.FC = () => {
  const [searchParams] = useSearchParams();

  const { isAuthenticated } = useAuth0();
  // capture auth0 error due to trial expiration and convey it to the user
  if (
    searchParams.get('error') === 'access_denied' &&
    searchParams.get('error_description') === 'trial_expiration'
  ) {
    return <h2>Trial Expired</h2>;
  }
  return isAuthenticated ? <ProtectedRoutes /> : <PublicRoutes />;
};
